.page {
    height: 100%;
    padding: 40px;
}

.page-content {
    padding-inline: 20px;
}

.page-title {
    margin-block-end: 40px;
    width: fit-content;
}

.page-title::after {
    content: '';
    background-color: #5474cc;
    display: block;
    height: 8px;
}


@media only screen and (max-width: 780px) {
    .page {
        padding: 20px;
    }
    .page-content {
        padding-inline: 0;
    }
}