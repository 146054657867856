.topbar-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #475569;
    font-size: 1rem;
    padding: 0 15px;
}

.topbar-menu {
    display: flex;
    flex-grow: 1;
    margin-right: 25px;
    padding-right: 10px;
    border-right: 1px solid #475569;
}

.topbar-item {
    all: unset;
    display: inline;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px 5px;
    transition: all 300ms;
}

.topbar-item:hover {
    background-color: #475569;
}

#topbar-name {
    flex-grow: 1;
    font-weight: bold;
    text-align: end;
    color: #e2e8f0;
    padding: 23px;
}

@media only screen and (max-width: 780px) {
    .topbar-wrapper {
        font-size: 0.9rem;
        padding: 0 10px;
    }

    .topbar-item {
        padding: 5px 8px;
        margin: 10px 0;
    }

    #topbar-name {
        display: none;
    }
}