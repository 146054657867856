.switch-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch-body {
    background-color: #475569;
    width: 55px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.switch-button {
    background-color: #1e293b;
    box-shadow: 1px 1px 5px #334155;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: absolute;
    left: 36px;
    z-index: 5;
    transition: all 300ms;
}

.switch-label {
    position: absolute;
    left: 8px;
    font-size: 0.9em;
    padding-top: 4px;
    z-index: 10;
    cursor: default;
}

.switch-label:first-child {
    left: 44px;
}