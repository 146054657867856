video {
    height: 720px;
    width: auto;
}

@media only screen and (max-width: 780px) {
    video {
        height: auto;
        width: 90vw;
    }
}

@media only screen and (max-height: 680px) and (orientation: landscape) {
    video {
        height: auto;
        width: 50vw;
    }
}