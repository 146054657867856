.skill-img-wrapper {
    position: absolute;
    font-size: 50px;
}

@media only screen and (max-width: 780px) {
    .skill-img-wrapper {
        position: static;
    }

    .skill-img-wrapper svg {
        font-size: 35px;
    }
}