.project-card {
    --card-width: 320px;
    background-color: #334155;
    width: var(--card-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    padding-block-end: 20px;
    box-shadow: 1px 1px 5px #0004;
    transition: all 300ms;
}

.project-card:hover {
    background-color: #475569;
    box-shadow: 1px 1px 15px #0006;
}

.project-image {
    width: var(--card-width);
    height: var(--card-width);
    border-radius: 15px 15px 0 0;
}

.project-title {
    font-weight: bold;
    margin: 20px 0 5px 0;
}

.project-desc {
    padding-inline: 20px;
    padding-block-end: 5px;
    font-size: 1em;
    color: #94a3b8;
}

.project-card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}