#skills-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.skill-item {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 15px;
    text-align: end;
    align-items: center;
}
.skill-item div {
    width: 200px;
}

@media only screen and (max-width: 780px) {
    .skill-item div {
        width: 150px;
    }
}