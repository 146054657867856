#contact-container {
    background-color: #5474cc;
    height: 400px;
    padding-block: 20px;
    margin-block-start: 40px;
    color: #e2e8f0;
}
#contact-title {
    text-align: center;
    margin-block: 40px;
}
#contact-items {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
.contact-item {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
}
.contact-item>span{
    flex-wrap: 1;
}
.contact-item h2, h3{
    margin-block: 0 10px;
}
.contact-item a {
    all: unset;
    font-size: 1.2em;
    cursor: pointer;
}
.contact-icon {
    font-size: 30px;
    width: 75px;
    height: 75px;
    background-color:#334155;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
a.underline {
    text-decoration: underline;
}

@media only screen and (max-width: 780px) {
    #contact-items {
        flex-direction: column;
        align-items: center;
    }
    .contact-item {
        margin-block: 20px;
    }
    #contact-container {
        height: auto;
    }
    .contact-icon {
        width: 60px;
        height: 60px;
        font-size: 25px;
    }
}