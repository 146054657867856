#bg-img {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    opacity: 0.3;
    object-fit: cover;
    width: 100%;
    height: 90%;
    z-index: -1;
    position: absolute;
}

.main-wrapper {
    --content-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100svh - 50px);
}

.landing-title {
    width: var(--content-width);
    color: #e2e8f0;
    text-align: center;
}

.landing-title h1 {
    font-size: 2.5em;
    margin-bottom: 40px;
}

.landing-title h1::after {
    display: block;
    content: '';
    height: 5px;
    background-color: #5474cc;
}

.landing-title h2 {
    color: #cbd5e1;
    font-size: 1.3em;
}

#pic-wrapper {
    height: var(--content-width);
    width: var(--content-width);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

#pic {
    height: 300px;
    width: 300px;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.social-button-wrapper {
    margin: 0 10px;
    cursor: pointer;
}

.social-button-wrapper a {
    all: unset;
}

#arrow-down-wrapper {
    all:unset;
    position: absolute;
    bottom: 50px;
    animation: updown linear infinite 3s;
}

@media only screen and (max-width: 768px) {
    .main-wrapper {
        --content-width: 250px;
        flex-direction: column;
    }

    #pic-wrapper {
        width: 180px;
        height: 180px;
        margin-right: 0;
    }

    .landing-title h1 {
        font-size: 2em;
    }
    
    .landing-title h2 {
        font-size: 1em;
    }

    .landing-title {
        margin-left: 0px;
    }

    #pic {
        height: 180px;
        width: 180px;
    }

    #arrow-down-wrapper {
        bottom: 30px;
    }
}

@keyframes updown {
    48% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(12px);
    }
    74% {
        transform: translateY(0);
    }
    88% {
        transform: translateY(12px);
    }
    100% {
        transform: translateY(0);
    }
}