#skill-level-wrapper {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #05966955;
}
#skill-level-bar {
    height: 100%;
    border-radius: 5px;
    background-color: #059669;
}