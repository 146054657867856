.about-me-page-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 90vh;
}

#about-me-side {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#about-me-shape{
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: -1;
}

#about-me-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

#about-me-content p {
    font-size: 1.1em;
    text-align: justify;
    margin-bottom: 12px;
}

#about-me-skills {
    width: 100%;
    height: 100%;
    position: relative;
    animation: move ease-in-out infinite 8s;
}

#about-me-carousel{
    display: none;
}

@keyframes move {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(15px);
    }
    100% {
        transform: translateY(0);
    }
}

@media only screen and (max-width: 780px) {
    .about-me-page-wrapper {
        flex-direction: column-reverse;
        justify-content: center;
    }

    #about-me-content {
        width: 100%;
    }

    #about-me-carousel{
        display: block;
        margin-bottom: 5px;
        margin-top: 20px;
        overflow: hidden;
    }

    #about-me-side {
        display: none;
    }
}