.button {
    height: 40px;
    width: 150px;
    border-radius: 40px;
    background-color: #94a3b8;
    color: #020617;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px #1e293b;
    border: 2px solid #94a3b8;
    cursor: pointer;
    transition: all 300ms;
}

.button.bordered {
    color: #cbd5e1;
    background-color: transparent;
}

.button.bordered:hover {
    border-color: #cbd5e1;
    color: #020617;
}

.button:hover {
    background-color: #cbd5e1;
    border-color: #cbd5e1;
    box-shadow: 1px 1px 15px #1e293b;
}

.button-icon.en {
    font-size: 15px;
    padding-top: 3px;
    padding-right: 5px;
}


.button-icon.fa {
    font-size: 15px;
    padding-top: 8px;
    padding-left: 5px;
}