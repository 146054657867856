#footer-container {
    height: 120px;
    background-color: #1e293b;
    border-top: 4px solid #0f172a;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#footer-links {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}
#footer-links a {
    all: unset;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}
#footer-text {
    padding-inline-start: 15px;
}