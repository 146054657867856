#modal-wrapper {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0008;
    display: flex;
    align-items: center;
    justify-content: center;
}
#modal-close {
    cursor: pointer;
}
#modal-close:hover {
    color: white;
}
#modal-title {
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-inline: 15px;
}
#modal-title p {
    flex-grow: 1;
    font-size: 1.2em;
}
#modal-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}
#modal-container {
    min-width: 600px;
    min-height: 50%;
    background-color: #334155;
    border-radius: 10px;
}

@media only screen and (max-width: 780px) {
    #modal-container {
        min-width: 300px;
        width: 95vw;
        min-height: 0;
        background-color: #334155;
        border-radius: 10px;
    }
    #modal-content {
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-height: 680px) and (orientation: landscape) {
    #modal-container {
        min-width: 300px;
        width: 55vw;
    }
}