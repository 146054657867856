.tag-container {
    height: 25px;
    background-color:#1e293b;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55px;
    padding-inline: 5px;
    border-radius: 6px;
}

.tag-name {
    padding-top: 3px;
    font-size: small;
    color: #94a3b8;
}