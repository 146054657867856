#carousel-container {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

#carousel-track {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200%;
    position: absolute;
    left: 0;
    height: 100%;
    gap: 20px;
    animation: scroll linear infinite 20s;
}

@keyframes scroll {
    0% {
        left: 0
    }
    100% {
        left: -100%;
    }
}