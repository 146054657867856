.stt-wrapper {
    position: fixed;
    bottom: 60px;
    right: 40px;
    height: 50px;
    width: 50px;
    background-color: #475569;
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.stt-wrapper:hover {
    background-color: #64748b;
}